
import importComponents from '@/utils/import-components';
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter, onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'NotFound',
  components: {
    ...importComponents(
    ),
  },
  setup() {
    const router = useRouter();
    const time = ref(5);
    const { t } = useI18n();
    let timer: number;
    const timeChange = () => {
      timer = setInterval(() => {
        time.value -= 1;
        if (time.value === 0) {
          router.push('/');
          clearInterval(timer);
        }
      }, 1000);
    };
    onMounted(() => {
      timeChange();
    });
    onBeforeRouteLeave(() => {
      clearInterval(timer);
    });
    return {
      time,
      t,
    };
  },
});
