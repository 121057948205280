<template>
    <div class="not-found-wrapper">
        <div class="not-found">
            <div class="oops">{{ t('notFound.oops') }}</div>
            <div class="not-exist">{{ t('notFound.message1') }} {{time}} {{ t('notFound.message2') }}</div>
            <router-link class="btn" to="/">{{ t('notFound.goHome') }}</router-link>
        </div>
    </div>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter, onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'NotFound',
  components: {
    ...importComponents(
    ),
  },
  setup() {
    const router = useRouter();
    const time = ref(5);
    const { t } = useI18n();
    let timer: number;
    const timeChange = () => {
      timer = setInterval(() => {
        time.value -= 1;
        if (time.value === 0) {
          router.push('/');
          clearInterval(timer);
        }
      }, 1000);
    };
    onMounted(() => {
      timeChange();
    });
    onBeforeRouteLeave(() => {
      clearInterval(timer);
    });
    return {
      time,
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/mixin.scss";
    @import '@/assets/styles/variables.scss';

    .not-found-wrapper {
        width: 100%;
        background: #fff;
        .not-found {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            width: 1280px;
            background: #fff;

            .oops {
                font-size: 30px;
                font-weight: 800;
                color: $font-color;
                line-height: 36px;
                margin-top: 140px;
            }

            .not-exist {
                width: 677px;
                height: 84px;
                font-size: 20px;
                font-weight: 400;
                color: $font-color-weak;
                line-height: 28px;
                margin-top: 55px;
                text-align: left;
            }

            .btn {
                @include flex-center();
                min-width:216px;
                height: 44px;
                padding:0 10px;
                background: $theme-color;
                border-radius: 4px;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                margin-top: 60px;
                margin-bottom: 209px;
                cursor: pointer;
                color: #FFFFFF;
            }
        }
    }
</style>
